import GATSBY_COMPILED_MDX from "/opt/build/repo/gatsby/src/posts/cities/barcelone.mdx";
import React from "react";
import {graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import Layout from "../components/layout";
import Footer from "../components/footer";
import Seo from "../components/seo";
import ShareButton from "../components/shareButton";
import BookingChecklist from "../components/bookingChecklist";
import LandmarkCard from "../components/landmarkCard";
export function Head({data}) {
  const {mdx} = data;
  const {frontmatter} = mdx;
  const {title, description, path} = frontmatter;
  return React.createElement(Seo, {
    title: title,
    description: description,
    slug: path,
    image: `/thumbs/incontournables${path}.png`
  });
}
function Template({data, pageContext, children}) {
  const {mdx} = data;
  const {frontmatter} = mdx;
  const {title, city, countryCode, path} = frontmatter;
  const {cityData} = pageContext;
  const url = `${process.env.GATSBY_BASEURL}${path}`;
  let mustSeeLandmarks = [];
  const nonEligibleCities = new Set(["Florence", "Istanbul", "Milan", "Rome", "Venise"]);
  if (!nonEligibleCities.has(city) && cityData?.features) {
    mustSeeLandmarks = cityData.features.filter(feature => feature.properties.category === "A voir");
  }
  const filterLandmarksByRange = range => {
    if (!range || range.start === undefined || range.end === undefined) return mustSeeLandmarks;
    const {start, end} = range;
    const adjustedStart = start - 1;
    const adjustedEnd = end - 1;
    return mustSeeLandmarks.slice(adjustedStart, adjustedEnd + 1);
  };
  const LandmarkCards = ({range}) => {
    const filteredLandmarks = filterLandmarksByRange(range);
    return React.createElement(React.Fragment, null, filteredLandmarks.map((feature, idx) => React.createElement(LandmarkCard, {
      key: idx,
      feature: feature
    })));
  };
  return React.createElement(Layout, {
    content: "blog"
  }, React.createElement("article", null, React.createElement("div", {
    className: "blog-post-container"
  }, React.createElement("div", {
    className: "blog-post"
  }, React.createElement("header", null, React.createElement("div", null, React.createElement("h1", null, title))), React.createElement("aside", null, React.createElement(ShareButton, {
    title: title,
    url: url
  })), React.createElement("main", null, React.createElement("section", null, React.createElement(BookingChecklist, {
    city: city,
    countryCode: countryCode
  })), React.createElement(MDXProvider, {
    components: {
      LandmarkCards
    }
  }, children))))), React.createElement(Footer, {
    city: city
  }));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query ($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        title
        description
        city
        countryCode
      }
    }
  }
`;
